/**
 * Manage loading spinner
 * NOTE: css is defined in resources/sass
 * This file is compiled via vite
 */

window.taagSpinner = (function() {
    function showLoading() {
        document.querySelector('#loading').classList.add('loading');
        document.querySelector('#loading-content').classList.add('loading-content');
    }

    /**
     * Hide spinner
     */

    function hideLoading() {
        document.querySelector('#loading').classList.remove('loading');
        document.querySelector('#loading-content').classList.remove('loading-content');
    }

    return {
        showLoading: function() {
            return showLoading();
        },
        hideLoading: function() {
            return hideLoading();
        },
    };

})();
