import defaultTheme from 'tailwindcss/defaultTheme';
import forms from '@tailwindcss/forms';
import flowbite from 'flowbite/plugin';

/** @type {import('tailwindcss').Config} */

export default {
    content: [
        './vendor/laravel/framework/src/Illuminate/Pagination/resources/views/*.blade.php',
        './storage/framework/views/*.php',
        './resources/views/**/*.blade.php',
        './resources/**/*.js',
        "./node_modules/flowbite/**/*.js"
    ],

    theme: {
        extend: {
            colors: {
                'cod-gray': {
                    '50': '#f6f6f6',
                    '100': '#e7e7e7',
                    '200': '#d1d1d1',
                    '300': '#b0b0b0',
                    '400': '#888888',
                    '500': '#6d6d6d',
                    '600': '#5d5d5d',
                    '700': '#4f4f4f',
                    '800': '#454545',
                    '900': '#3d3d3d',
                    '950': '#121212',
                },
                'persian-red': {
                    '50': '#fdf3f3',
                    '100': '#fde3e3',
                    '200': '#fccccc',
                    '300': '#f8a9a9',
                    '400': '#f27777',
                    '500': '#e84b4b',
                    '600': '#d22b2b',
                    '700': '#b22323',
                    '800': '#942020',
                    '900': '#7b2121',
                    '950': '#420d0d',
                },
                'forest-green': {
                    '50': '#f1fcf1',
                    '100': '#e0f9df',
                    '200': '#c2f1c1',
                    '300': '#92e491',
                    '400': '#5acf59',
                    '500': '#34b433',
                    '600': '#228b22',
                    '700': '#207521',
                    '800': '#1e5d1f',
                    '900': '#1a4d1b',
                    '950': '#092a0b',
                },
                'royal-blue': {
                    '50': '#f1f4fd',
                    '100': '#dfe7fa',
                    '200': '#c5d5f8',
                    '300': '#9ebbf2',
                    '400': '#7097ea',
                    '500': '#4169e1',
                    '600': '#3957d7',
                    '700': '#3044c5',
                    '800': '#2d39a0',
                    '900': '#29347f',
                    '950': '#1d224e',
                },
                'tussock': {
                    '50': '#f8f5ee',
                    '100': '#eee8d3',
                    '200': '#dfcfa9',
                    '300': '#ccb178',
                    '400': '#c09b5b',
                    '500': '#ad8345',
                    '600': '#956839',
                    '700': '#784f30',
                    '800': '#65422e',
                    '900': '#58392b',
                    '950': '#321d16',
                },
                'ochre': {
                    '50': '#fcf7ee',
                    '100': '#f6e8cf',
                    '200': '#ecd09b',
                    '300': '#e2b367',
                    '400': '#db9b44',
                    '500': '#d27d2d',
                    '600': '#ba6025',
                    '700': '#9b4522',
                    '800': '#7e3822',
                    '900': '#682f1f',
                    '950': '#3b160d',
                },
            },
            fontSize: {
                // assumtion: root element pixel default size is 16px
                h1: '1.5rem',  // = 18pt = 24px
                h2: '1.33rem', // = 16pt = 21,33px
                h3: '1.17rem', // = 14pt = 18,66px
                h4: '1.08rem', // = 13pt = 17,33px
                h5: '1rem',    // = 12pt = 16px
                h6: '0.83rem', // = 10pt = 13,33px
            },
            fontFamily: {
                sans: ['Nunito', ...defaultTheme.fontFamily.sans],
            },
            gridTemplateColumns: {
                // Simple 16 column grid
                '16': 'repeat(16, minmax(0, 1fr))',
                'lofi-two-columns': '200px auto',

                // Complex site-specific column configuration
                // 'footer': '200px minmax(900px, 1fr) 100px',
            },
            // minHeight: (theme) => ({
            //     ...theme('spacing'),
            // }),
        },
    },

    variants: {
        extend: {
            // opacity: ['disabled'],
        },
    },

    plugins: [
        forms,
        flowbite
    ],
};
