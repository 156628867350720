// Upgarde from laravel mix to vite - see https://github.com/laravel/vite-plugin/blob/main/UPGRADE.md
// and https://github.com/laravel/laravel/pull/5895/files
import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

// Let's make the translations in js files everywhere available
// Initialize the translation instance in your js file with:
// let translator = new I18n('taagTranslations');
// See example in scorings.js
// See https://github.com/conedevelopment/i18n for details
import I18n from './vendor/i18n';
window.I18n = I18n;
