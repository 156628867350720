import twConfig from './twConfig.js';

window.taagScoringsVisualization = (function() {
    let container = document.getElementById('chart-scorings');
    if ( ! container) {
        return;
    }

    let chart = echarts.init(container, null, {height: 'auto', width: 'auto'});
    let colors = twConfig.theme.colors;
    let seriesItems = [];
    let translator = new I18n('taagTranslations');

    window.addEventListener('resize', function() {
        resizeChartDelayed(250);
        updateChartAfterResizing();
        // console.log('--> chart was resized');
    });

    function init(ddaprojects) {
        let data = []
        seriesItems = [];
        // console.log('--> seriesItem array: ', seriesItems)
        ddaprojects.forEach(function(ddaprojectItem) {
            data.push(getProjectData(ddaprojectItem))
        })
        // console.table(data);
        data.forEach(function(dataItem, index) {
            seriesItems.push(getSeriesItem(dataItem));
        });
        // console.log('--> seriesItem array in init(): ');
        // console.table(seriesItems);

        if ( ! seriesItems.length) {
            return;
        }

        let option = createOption(seriesItems);
        clearChart();
        setChartOption(option);
    }

    function addAbbreviationText(option) {
        let text = translator.trans('dda.PL = Personalization Level');
        option.graphic.elements[0].children.push({
            type: 'text',
            id: 'text-abbreviation',
            left: 50,
            bottom: 25,
            zLevel: 100,
            style: {
                fill: colors["cod-gray"]["500"],
                text: text,
                font: 'normal normal 12px "Arial", sans-serif'
            },
            silent: true,
            invisible: false
        })
    }

    function addQuadrantDescriptions(option, quadrants = []) {
        let quadrantDescriptions = getQuadrantDescriptions();

        for (let i = 0; i < quadrants.length; i++) {
            option.graphic.elements[0].children.push({
                type: 'rect',
                id: 'box-' + i,
                left: (i === 0 ? 50 : option.graphic.elements[0].children[i-1].left + 260),
                bottom: 50,
                scaleX: 1,
                scaleY: 1,
                zLevel: 100,
                shape: {
                    width: 60,
                    height: 60
                },
                style: {
                    // Here are configurations for style of the element, like `fill`, `stroke`,
                    // `lineWidth`, `shadowBlur`, ...
                    //fill: colors["royal-blue"]["200"],
                    stroke: quadrantDescriptions[i][0],
                    borderType: 'solid',
                    lineWidth: '2',
                },
                textContent: {
                    style: {
                        text: quadrantDescriptions[i][1] + '\n\n' + quadrantDescriptions[i][2],
                        fill: colors["cod-gray"]["500"],
                        // Font size, font type, font weight, font color, follow the form of css font.
                        font: 'normal normal 16px "Arial", sans-serif'
                    }
                },
                textConfig: {
                    position: 'right',
                    //position: [110, 30]
                },
                // Whether response to mouse events / touch events.
                silent: true,
                // Whether the element is visible.
                invisible: false,
                // Event handler can also be onmousemove, ondrag, ... (listed below)
                onclick: function () {
                    console.log('--> user clicked on box ' + i);
                }
            });
        }
    }

    function addSeparatorLine(option) {
        let config = {
            leftValue: 20,
            bottomValue: 125,
            width: 1150,
            zLevel: 100
        }

        // debugger;
        const groupQuadrantDescriptionsWidth = option.graphic.elements[0].width;

        option.graphic.elements[0].children.push({
            type: 'line',
            id: 'separator-line-quadrant-descriptions',
            // x: 10,
            // y: 600,
            left: config.leftValue,
            bottom: config.bottomValue, // @TODO set bottom value dynamically, based on number of data items
            zLevel: config.zLevel,
            shape: {
                x1: 0,
                y1: 10,
                x2: groupQuadrantDescriptionsWidth - (config.leftValue * 2),
                y2: 10
            },
            style: {
                // Here are configurations for style of the element, like `fill`, `stroke`,
                // `lineWidth`, `shadowBlur`, ...
                // fill: colors["royal-blue"]["200"],
                fill: 'none',
                stroke: 'gray',
                borderType: 'dotted',
                lineWidth: 1,
            },
            silent: true,
            invisible: false
        })
    }

    function clearChart() {
        chart.clear();
    }

    function createOption(seriesItems) {
        let option = getOption(
            sortQuadrantsByPrioritization(seriesItems)
        );
        let quaddrantDescriptions = getQuadrantDescriptions();
        // console.log(quaddrantDescriptions)
        // console.table(quaddrantDescriptions)
        addQuadrantDescriptions(option, quaddrantDescriptions);
        addSeparatorLine(option);
        addAbbreviationText(option);
        // console.log(option)

        return option;
    }

    function getChartInstance() {
        return chart;
    }

    function getColor(dataItem) {
        let xAxisValue = Number(dataItem[0]);
        let yAxisValue = Number(dataItem[1]);

        return getQuadrantInfo(xAxisValue, yAxisValue, 'color')
    }

    function getOption(seriesItems) {
        // console.log('seriesItems[0][5]: ', seriesItems[0]['data'][0][5])
        // console.log('seriesItems[0][taagNormalizeToValue]: ', seriesItems[0]['taagNormalizeToValue']);
        // console.log('seriesItems: ', seriesItems)

        let legendData = seriesItems.map(seriesItem => seriesItem['name']);
        // console.log('--> legendData: ', legendData);

        let minorSplitLineConfig ={
            show: true,
            lineStyle: {
                width: 0.15
            }
        };

        let splitLineConfig = {
            lineStyle: {
                // color: colors['cod-gray']['50'],
                color: '#ABABAB',
                type: 'solid' // other options: dashed, dotted
            }
        };

        let axisLabelConfig = {
            show: true,
            // padding: [0, 6, 0, 0],
            interval: 0,
            showMinLabel: true,
            showMaxLabel: true,
            // fontSize: 11,
            // color: '#fff',
            // hideOverlap: true,
            formatter: function (value, index) {
                return value.toFixed(2);
            },
        };

        const chartWidth = chart.getWidth();
        // console.log('--> chartWidth in option: ', chartWidth);
        let chartPadding = 20;

        let option = {
            graphic:  {
                'elements': [
                    {
                        'type': 'group',
                        'id': 'section-quadrant-descriptions',
                        'left': 20,
                        'bottom': 10,
                        'width': chartWidth - (2 * chartPadding),
                        children: [], // just a placeholder to be filled later dynamically
                    }
                ]
            },
            // backgroundColor: new echarts.graphic.RadialGradient(0.3, 0.3, 0.8, [{
            //     offset: 0,
            //     color: '#f7f8fa'
            // }, {
            //     offset: 1,
            //     color: '#cdd0d5'
            // }]),
            // backgroundColor: colorsCodGray["950"],
            backgroundColor: colors['cod-gray']['950'],
            title: {
                text: translator.trans('dda.TAAG-Data Driven Ads Hypothesis Matrix'),
                left: '5%',
                top: '3%',
                textStyle: {
                    color: colors['cod-gray']['50']
                }
            },
            legend: {
                show: true,
                type: 'scroll',
                data: legendData,
                left: '50px',
                top: '775px',
                orient: 'horizontal',
                align: 'left',
                padding: [
                    0, // up
                    50, // right
                    0, // down
                    0, // left
                ],
                textStyle: {
                    color: colors["cod-gray"]["500"],
                    width: '150',
                    overflow: 'truncate'
                },
                // padding: 5,
                itemGap: 15,
                itemWidth: 30,
                itemHeight: 30,
                itemStyle: {
                    borderColor: 'white',
                    borderWidth: 2,
                }
            },
            grid: {
                // backgroundColor: '#41FF40', // transparent by default
                show: true,
                left: '10%',
                right: '20%',
                top: '10%',
                width: 'auto',
                height: 600,
                // y: dimensions.height, // set space for grid
                // y2: 400 // set space for legend at the bottom, below the grid
            },
            xAxis: {
                name: translator.trans('dda.Personalization Level (normalized)'),
                nameGap: 20,
                nameLocation: 'end',
                min: 0,
                max: seriesItems[0]['taagNormalizeToValue'],
                splitLine: splitLineConfig,
                splitNumber: 2,
                minorSplitLine: minorSplitLineConfig,
                maxInterval: seriesItems[0]['taagNormalizeToValue'] / 2,
                // axisPointer: {show: true, type: 'none'},
                axisLabel: axisLabelConfig,
            },
            yAxis: {
                name: translator.trans('dda.Effort (normalized)'),
                nameGap: 20,
                nameLocation: 'end',
                min: 0,
                max: seriesItems[0]['taagNormalizeToValue'],
                splitLine: splitLineConfig,
                // scale: true,
                splitNumber: 2,
                minorSplitLine: minorSplitLineConfig,
                maxInterval: seriesItems[0]['taagNormalizeToValue'] / 2,
                // axisPointer: {show: true, type: 'none'}
                axisLabel: axisLabelConfig,
            },
            series: []
        };

        seriesItems.forEach(function (seriesItem) {
            option.series.push(seriesItem);
        });

        return option;
    }

    function getKpiName(ddaproject) {
        return 'kpi' in ddaproject && ddaproject.kpi !== null && 'name' in ddaproject.kpi ? ddaproject.kpi.name : '';
    }

    function getProjectData(ddaproject) {
        let effortNormalized = parseInt(ddaproject.effortNormalized);
        let impactNormalized = parseInt(ddaproject.impactNormalized);
        let name = ddaproject.name;
        let kpi = getKpiName(ddaproject);
        let label = `${translator.trans('Hypothesis Name')}: ${name} \n ${translator.trans('Effort normalized')}: ${effortNormalized} \n ${translator.trans('Personalization Level normalized')}:  ${impactNormalized}`;
        let normalizeToValue = parseInt(ddaproject.normalizeToValue);

        // [x-axis value, y-axis value, label]
        return [
            impactNormalized,
            effortNormalized,
            label,
            name,
            kpi,
            normalizeToValue,
        ];
    }

    function getQuadrants() {
        return  {
            'upper left': {
                index: 0,
                color: colors["yellow"]['500'],
                name: 'upper left'
            },
            'upper right': {
                index: 1,
                color: colors['royal-blue']['500'],
                name: 'upper right'
            },
            'lower left': {
                index: 2,
                color: colors['persian-red']['500'],
                name: 'lower left'
            },
            'lower right': {
                index: 3,
                color: colors['forest-green']['500'],
                name: 'lower right'
            }
        };
    }

    function getQuadrantDescriptions() {
        let quadrantDescriptions = [];
        quadrantDescriptions[0] = [colors["forest-green"]["500"], 'Test ASAP - PRIO A', translator.trans('dda.Low Effort - High PL')];
        quadrantDescriptions[1] = [colors["royal-blue"]["500"], 'Prioritize - PRIO B', translator.trans('dda.High Effort - High PL')];
        quadrantDescriptions[2] = [colors.yellow["500"], 'Iterate - PRIO C', translator.trans('dda.High Effort - Low PL')];
        quadrantDescriptions[3] = [colors["persian-red"]["500"], 'Iterate - PRIO D', translator.trans('dda.Low Effort - Low PL')];

        return quadrantDescriptions;
    }

    function getQuadrantInfo(xAxisValue, yAxisValue, type = 'index') {
        let quadrants = getQuadrants();
        let calculatedQuadrant = -1;

        if (yAxisValue >= 0 && yAxisValue <= 50) {
            if (xAxisValue >= 0 && xAxisValue <= 50) {
                calculatedQuadrant = "lower left";
            } else if (xAxisValue > 50 && xAxisValue <= 100) {
                calculatedQuadrant = "lower right";
            }
        } else if (yAxisValue > 50 && yAxisValue <= 100) {
            if (xAxisValue >= 0 && xAxisValue <= 50) {
                calculatedQuadrant = "upper left";
            } else if (yAxisValue > 50 && yAxisValue <= 100) {
                calculatedQuadrant = "upper right";
            }
        }

        if ( calculatedQuadrant === -1 ) {
            throw new Error(`Type  ${type} does not exist.`);
        }

        // console.log('--> quadrants[calculatedQuadrant][type]: ', quadrants[calculatedQuadrant][type]);

        return quadrants[calculatedQuadrant][type];
    }

    /**
     * Get a formatted specific series item
     */
    function getSeriesItem(dataItem) {
        return {
            type: 'scatter',
            name: dataItem[3],
            data: [dataItem],
            taagNormalizeToValue: dataItem[5],
            taagQuadrantIndex: getQuadrantInfo(dataItem[0], dataItem[1], 'index'),
            taagQuadrantName: getQuadrantInfo(dataItem[0], dataItem[1], 'name'),
            symbolSize: 40,
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    formatter: function (param) {
                        return param.data[2];
                    },
                    position: 'bottom'
                }
            },
            itemStyle: {
                // shadowBlur: 10,
                // shadowColor: 'rgba(120, 36, 50, 0.5)',
                // shadowOffsetY: 5,
                color: getColor(dataItem),
                borderColor: 'white',
                borderWidth: 2,
                // color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
                //     offset: 0,
                //     color: 'rgb(251, 118, 123)'
                // }, {
                //     offset: 1,
                //     color: 'rgb(204, 46, 72)'
                // }])
            }
        };
    }

    function resizeChartDelayed(delay = 500) {
        let timer = setTimeout(function(chart) {
            chart.resize();
        }, delay, chart);
    }

    function setChartOption(option) {
        chart.setOption(option);
    }

    function sortQuadrantsByPrioritization(seriesItems) {
        let sortedSeriesItems = [];
        sortedSeriesItems.push(seriesItems.filter((item) => item['taagQuadrantIndex'] === 3));
        sortedSeriesItems.push(seriesItems.filter((item) => item['taagQuadrantIndex'] === 1));
        sortedSeriesItems.push(seriesItems.filter((item) => item['taagQuadrantIndex'] === 2));
        sortedSeriesItems.push(seriesItems.filter((item) => item['taagQuadrantIndex'] === 0));

        return sortedSeriesItems.flat();
    }

    function updateChart(kpi, checkedKpis) {
        try {
            let clonedSeriesItems = [...seriesItems];
            let filteredSeriesItems = [];
            clonedSeriesItems.forEach(function (clonedSeriesItem, index) {
                let clonedItemKpi = clonedSeriesItem.data[0][4];
                if (checkedKpis.includes(clonedItemKpi)) {
                    filteredSeriesItems.push(clonedSeriesItem);
                }
            })
            let option = {};
            if (filteredSeriesItems.length) {
                option = createOption(filteredSeriesItems);
            }
            clearChart();
            setChartOption(option);
            resizeChartDelayed();

            return true;
        } catch (exception) {
            // console.log('--> exception: ', exception.message)
            return false;
        }
    }

    function updateChartAfterResizing() {
        let option = chart.getOption();
        if ( ! option
            || ! option instanceof Object
            || ! option.hasOwnProperty('graphic')
            || ! option.graphic.length
        ) {
            return;
        }

        updateSeparatorLineAfterResizing();

        let chartWidth = chart.getWidth();
        let leftValuesDefault = {0: 50, 1: 310, 2: 570, 3: 830};
        if (chartWidth > 1030) {
            updateQuadrantDescriptionsAfterResizing(1,'16px', leftValuesDefault);
        } else if (chartWidth >= 800 && chartWidth <= 1030) {
            updateQuadrantDescriptionsAfterResizing(0.75,'14px',{0: 40, 1: 260, 2: 480, 3: 700});
        } else if (chartWidth >= 600 && chartWidth < 800) {
            updateQuadrantDescriptionsAfterResizing(0.5,'11px', {0: 30, 1: 200, 2: 370, 3: 540} );
        } else if (chartWidth >= 400 && chartWidth < 600) {
            updateQuadrantDescriptionsAfterResizing(0.25, '9px', {0: 20, 1: 150, 2: 270, 3: 390});
        }
    }

    function updateSeparatorLineAfterResizing() {
        let option = chart.getOption();
        let graphicElements = option.graphic[0].elements;
        // console.log('--> graphicElements: ', graphicElements);

        // let graphicElementSeparatorLine = graphicElements.filter((graphicElement) => graphicElement.id === 'separator-line-quadrant-descriptions');
        // console.log('--> graphicElementSeparatorLine: ', graphicElementSeparatorLine);
        // var graphicElementsRemaining = graphicElements.filter((graphicElement) => graphicElement.id !== 'separator-line-quadrant-descriptions');

        // let graphicElementGroup = graphicElements.filter((graphicElement) => graphicElement.id === 'section-quadrant-descriptions');
        // console.log('--> graphicElementGroup: ', graphicElementGroup);
        // let graphicElementsRemaining = graphicElements.filter((graphicElement) => graphicElement.id !== 'section-quadrant-descriptions');

        const isGraphicElementToSearch = (element) => element.id === 'separator-line-quadrant-descriptions';
        const indexSearchedElement = graphicElements.findIndex(isGraphicElementToSearch);
        // console.log('--> index for the searched graphic element', indexSearchedElement);
        const adjustedWidth = chart.getWidth() - (2*20);
        // console.log('--> adjustedWith: ', adjustedWidth);
        graphicElements[indexSearchedElement].shape.x2 = adjustedWidth;

        chart.setOption({ graphic: graphicElements });
    }

    function updateQuadrantDescriptionsAfterResizing(scaleValue, fontSize, leftValues = {}) {
        let option = chart.getOption();
        let graphicElements = option.graphic[0].elements;
        // console.log('--> graphicElements: ', graphicElements);

        let graphicElementsBox = graphicElements.filter((graphicElement) => graphicElement.id.includes('box'));
        // console.log('--> graphicElementsBox: ', graphicElementsBox);

        if ( ! graphicElementsBox.length) {
            return;
        }

        const regexFontSizeInPixels = /(\d+px)/i;
        for (let i=0; i<graphicElementsBox.length; i++) {
            graphicElementsBox[i].scaleX = scaleValue;
            graphicElementsBox[i].scaleY = scaleValue;
            graphicElementsBox[i].left = leftValues[i];
            // sample font string: normal normal 16px \"Arial\", sans-serif";
            let currentFontString = graphicElementsBox[i].textContent.style.font;
            // replace the font size in font string
            graphicElementsBox[i].textContent.style.font = currentFontString.replace(regexFontSizeInPixels, fontSize);
            // console.log('--> new fontSize: ', graphicElementsBox[i].textContent.style.font);
            // console.log('--> new scaleValue (x + y): ', scaleValue);
        }

        chart.setOption({ graphic: graphicElements });
    }


    return {
        init: function(ddaprojects) {
            return init(ddaprojects);
        },
        clearChart: function() {
            return clearChart();
        },
        getChartInstance: function() {
            return getChartInstance()
        },
        getTranslator: function() {
            return translator;
        },
        resizeChartDelayed: function(delay) {
            return resizeChartDelayed(delay);
        },
        setChartOption: function(option) {
            return setChartOption(option);
        },
        updateChart: function(kpi, checkedKpisArray) {
            return updateChart(kpi, checkedKpisArray);
        },
    };
})();
